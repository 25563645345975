import React from 'react'
import { Pane, Heading, Paragraph, UnorderedList, ListItem } from '@muhsis/ui'
import { Grid } from 'react-styled-flexboxgrid'

import { SpotlightLight } from '../blocks'
import { Layout, SEO, BackgroundImageGrid } from '../components'
import { Header, Footer } from '../blocks'

const CookiesPage = () => (
  <Layout>
    <SEO
      title="Çerez Politikamız"
      keywords={[
        `muhsis çerez politikası`,
        `web tabanlı ön muhasebe`,
        `muhasebe`,
        `web muhasebe programı`,
        `online muhasebe programı`,
      ]}
    />
    <BackgroundImageGrid>
      <Grid>
        <Header />
        <SpotlightLight
          title="Çerez Politikamız"
          description="Tüm web sitesi ve uygulamalarımızda hangi tür çerezlerin hangi koşullar ve amaçlar ile kullanıldığını buradan öğrenebilirsiniz."
        />
        <Pane
          maxWidth={700}
          marginX="auto"
          marginTop="3rem"
          marginBottom="5rem"
        >
          <Heading size={600} marginBottom="1rem">
            Çerez nedir?
          </Heading>
          <Paragraph marginBottom="1rem">
            Çerezler, bilgisayarınız ya da mobil cihazınız üzerinden ziyaret
            ettiğiniz internet siteleri tarafından cihazınıza veya ağ sunucusuna
            depolanan küçük metin dosyalarıdır.
          </Paragraph>

          <Heading size={600} marginBottom="1rem">
            Çerezler hangi tür veriler saklanır?
          </Heading>
          <Paragraph marginBottom="1rem">
            İnternet sitelerinde yer alan çerezlerde, türüne bağlı olarak,
            siteyi ziyaret ettiğiniz cihazdaki tarama ve kullanım tercihlerinize
            ilişkin veriler toplanmaktadır. Bu veriler, eriştiğiniz sayfalar,
            incelediğiniz hizmet ve ürünler, tercih ettiğiniz dil seçeneği ve
            diğer tercihlerinize dair bilgileri kapsamaktadır.
          </Paragraph>

          <Heading size={600} marginBottom="1rem">
            Çerezleri hangi amaçlarla kullanmaktasınız?
          </Heading>
          <Paragraph marginBottom="1rem">
            Sitede tercih ettiğiniz dil ve diğer ayarları içeren bu küçük metin
            dosyaları, siteye bir sonraki ziyaretinizde tercihlerinizin
            hatırlanmasına ve sitedeki deneyiminizi iyileştirmek için
            hizmetlerimizde geliştirmeler yapmamıza yardımcı olur. Böylece bir
            sonraki ziyaretinizde daha iyi ve kişiselleştirilmiş bir kullanım
            deneyimi yaşayabilirsiniz.
          </Paragraph>

          <Heading size={600} marginBottom="1rem">
            Hangi çerezleri kullanıyorsunuz?
          </Heading>
          <Paragraph marginBottom="1rem">
            Aşağıda sitelerimizde kullanılan çerezlerin listesini
            bulabilirsiniz.
          </Paragraph>
          <UnorderedList size={400} marginBottom="2rem">
            <ListItem>
              <strong>muhsis.com, app.muhsis.com : </strong> Size ait kullanıcı
              ve oturum bilgileri ile ilgili çerezler tarafımızca saklanır.
            </ListItem>
            <ListItem>
              <strong>cloudflare.com : </strong> Sistemlerimizi saldırılara
              karşı korumak, performans artışı sağlamak amacı ile kullandığımız
              3. parti bir ürünün sakladığı çerezler.
            </ListItem>
            <ListItem>
              <strong>drift.com : </strong> Web sitemize canlı mesajlaşma
              fonksiyonalitesi sağlayan ürünün sakladığı çerezler.
            </ListItem>
            <ListItem>
              <strong>hotjar.com : </strong> Kullanım istatistikleri toplamamıza
              yarayan (kullanıcı verileri değil, kullanıcı deneyimi ile ilgili),
              3. parti bir aracın sakladığı çerezler.
            </ListItem>
          </UnorderedList>

          <Heading size={600} marginBottom="1rem">
            Çerezlerin kullanılmasını nasıl engelleyebilirsiniz?
          </Heading>
          <Paragraph marginBottom="1rem">
            Çoğu tarayıcı çerezleri otomatik olarak kabul eder. Ancak dilerseniz
            çerezleri tarayıcınızın ayarlarını değiştirerek reddedebilirsiniz.
            Çerezleri reddettiğiniz takdirde sitemizdeki bazı özelliklerin ve
            hizmetlerin düzgün çalışamayabileceğini, sitemizin
            kişiselleştirilemeyebileceğini ve sizin deneyiminize göre
            özelleştirilemeyeceğini lütfen unutmayınız.
          </Paragraph>
        </Pane>
      </Grid>
    </BackgroundImageGrid>
    <Footer />
  </Layout>
)

export default CookiesPage
